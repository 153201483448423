a {
  color: rgb(72, 103, 145);
}

.mainholder {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: -10;
}

.portfolio-main {
  border-radius: 4px;
  width: 800px;
  min-height: 800px;
  margin: 20px;
  padding: 0px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  color: #121216;
  background-color: rgba(255, 255, 255, 0.925);
  backdrop-filter: blur(4px);
  z-index: 1;
}

#top h1 {
  padding: 48px 0px 4px 0px;
  font-size: 2.5em;
}

#switcher {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  height: 100%;
  font-size: 1.05em;
  font-family: "Archivo", "Franklin Gothic Medium", "Arial Narrow", Arial,
    sans-serif;
  line-height: 1.44;
  z-index: 1;
  overflow-y: auto;
}

.page::--webkit-scrollbar {
  width: 4px;
}

.page::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.page::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
​ #top-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

#top-content #s2 {
  font-weight: 500;
  font-family: "Lato", "Inter";
  text-decoration: none;
  color: #80c;
}

#znav {
  left: 0;
  bottom: 0;
  z-index: 9;
  width: 100%;
  position: sticky;
  display: flex;
  justify-content: space-evenly;
  background-color: rgba(255, 255, 255, 0.466);
  backdrop-filter: blur(4px);
  border-radius: 4px;
  overflow: hidden;
}

#znav button {
  border: none;
  background-color: transparent;
  font-family: "Space Mono";
  font-size: 0.925em;
  padding: 22px 11px;
}

#znav button:hover {
  font-weight: bold;
}

#contact form {
  width: 92%;
  display: flex;
  flex-direction: column;
  margin: 16px auto;
  font-family: "Archivo", "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

#contact form input {
  border: none;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 2px;
  padding: 8px;
  margin: 8px 0px;
}

#contact form textarea {
  resize: none;
  height: 175px;
  border: none;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 2px;
  padding: 8px;
  margin: 8px 0px;
}

#contact form button {
  border: none;
  background-color: rgb(71, 71, 71);
  color: white;
  font-family: "Space Mono", "Courier New", Courier, monospace;
  border-radius: 2px;
  padding: 8px;
}

#contact form button:hover {
  background-color: rgb(49, 49, 49);
}

#contact form #emailstatus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8em;
}

#tooltip {
  background-color: rgb(222, 222, 222, 0.5);
  padding: 5px 22px;
  border-radius: 4px;
  font-size: 0.85em;
  width: fit-content;
  margin: auto;
}

#topandcontent {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}

