@import url(https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,200;0,700;1,400&family=Inter:wght@100;400;700&family=Space+Mono&display=swap);
* {
  box-sizing: border-box;
  font-smooth: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: rgba(253, 253, 253, 0.966);
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: "Space Mono", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

#pinkish {
  background: #ac6a6f;
  background: linear-gradient(to bottom right, #ac6a6f, #cb5756);
}

#sealight {
  background-image: linear-gradient(
    to right top,
    #aac4ff,
    #a1cbf9,
    #a0d1f1,
    #a8d5e7,
    #b4d7df,
    #bedbdf,
    #c9dfe0,
    #d3e2e2,
    #dce9e9,
    #e6f0f0,
    #eff8f8,
    #f9ffff
  );
}

#thelake {
  background: #093028; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #237a57,
    #093028
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#moonlit {
  background: #0f2027; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2c5364,
    #203a43,
    #0f2027
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#htmlorange {
  background: #e44d26; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f16529,
    #e44d26
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#lush {
  background: #56ab2f; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #a8e063,
    #56ab2f
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#midnight {
  background: #232526; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #414345,
    #232526
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#minimalred {
  background: #f00000; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #dc281e,
    #f00000
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#nelson {
  background: #f2709c; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ff9472,
    #f2709c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#kyoto {
  background: #c21500; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffc500,
    #c21500
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#kashmir {
  background: #614385; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #516395,
    #614385
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

a {
  color: rgb(72, 103, 145);
}

.mainholder {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: -10;
}

.portfolio-main {
  border-radius: 4px;
  width: 800px;
  min-height: 800px;
  margin: 20px;
  padding: 0px 32px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  color: #121216;
  background-color: rgba(255, 255, 255, 0.925);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  z-index: 1;
}

#top h1 {
  padding: 48px 0px 4px 0px;
  font-size: 2.5em;
}

#switcher {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  -webkit-justify-content: stretch;
          justify-content: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
  width: 100%;
  height: 100%;
  font-size: 1.05em;
  font-family: "Archivo", "Franklin Gothic Medium", "Arial Narrow", Arial,
    sans-serif;
  line-height: 1.44;
  z-index: 1;
  overflow-y: auto;
}

.page::--webkit-scrollbar {
  width: 4px;
}

.page::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.page::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
​ #top-content {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

#top-content #s2 {
  font-weight: 500;
  font-family: "Lato", "Inter";
  text-decoration: none;
  color: #80c;
}

#znav {
  left: 0;
  bottom: 0;
  z-index: 9;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  background-color: rgba(255, 255, 255, 0.466);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border-radius: 4px;
  overflow: hidden;
}

#znav button {
  border: none;
  background-color: transparent;
  font-family: "Space Mono";
  font-size: 0.925em;
  padding: 22px 11px;
}

#znav button:hover {
  font-weight: bold;
}

#contact form {
  width: 92%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 16px auto;
  font-family: "Archivo", "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

#contact form input {
  border: none;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 2px;
  padding: 8px;
  margin: 8px 0px;
}

#contact form textarea {
  resize: none;
  height: 175px;
  border: none;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 2px;
  padding: 8px;
  margin: 8px 0px;
}

#contact form button {
  border: none;
  background-color: rgb(71, 71, 71);
  color: white;
  font-family: "Space Mono", "Courier New", Courier, monospace;
  border-radius: 2px;
  padding: 8px;
}

#contact form button:hover {
  background-color: rgb(49, 49, 49);
}

#contact form #emailstatus {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  font-size: 0.8em;
}

#tooltip {
  background-color: rgb(222, 222, 222, 0.5);
  padding: 5px 22px;
  border-radius: 4px;
  font-size: 0.85em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

#topandcontent {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: stretch;
          justify-content: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
}


